import { SubButton as Button } from '@riddler-co-jp/specc-ui-components';
import { TextWindow } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import Colors from '../../../../styles/colors';

interface CommonErrorOverlayProps {
  message?: string;
  onRetry?: () => void;
  retryButtonText?: string;
  onBackButton: () => void;
  backButtonText?: string;
}

const CommonErrorOverlay: React.FC<CommonErrorOverlayProps> = props => {
  const { message, onRetry, retryButtonText, onBackButton, backButtonText } =
    props;
  return (
    <ErrorOverlayWrapper>
      <Text bracket dark>
        <p>{message ?? '申し訳ありません。エラーが発生しました。'}</p>
        <Buttons>
          <Button size={'large'} color={'negative'} onClick={onBackButton}>
            {backButtonText ?? '閉じる'}
          </Button>
          {onRetry !== undefined && (
            <Button size={'large'} color={'positive'} onClick={onRetry}>
              {retryButtonText ?? 'リトライ'}
            </Button>
          )}
        </Buttons>
      </Text>
    </ErrorOverlayWrapper>
  );
};

const Text = styled(TextWindow)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
`;

const ErrorOverlayWrapper = styled.div`
  position: fixed;
  background-color: ${Colors.gray8_op090};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 11;
`;

const Buttons = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  padding: 2rem 0;
  left: 50%;

  button {
    margin-right: 4rem;
  }

  button:last-child {
    margin-right: 0;
  }
`;

export default CommonErrorOverlay;
