import { Spinner } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { useLoadingState } from '../../../../redux/selectors/appSelectors';
import Colors, { colorWithAlpha } from '../../../../styles/colors';

interface LoadingOverlayProps {}
const LoadingOverlay: React.FC<LoadingOverlayProps> = () => {
  const { text, visible } = useLoadingState();
  return (
    <>
      {visible && (
        <LoadingOverlayWrapper>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
          <Text>{text}</Text>
        </LoadingOverlayWrapper>
      )}
    </>
  );
};

const LoadingOverlayWrapper = styled.div`
  background-color: rgba(52, 58, 64, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const Text = styled.div`
  position: absolute;
  top: calc(50% + 7rem);
  left: 0;

  width: 100%;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.7rem;
  text-align: center;
  color: white;
  font-weight: bold;
`;

export default LoadingOverlay;
