import * as React from 'react';
import { useDispatch } from 'react-redux';

import { timeActions } from '../../../redux/actions/timeActions';
import { useTimer } from '../../../redux/selectors/timeSelectors';
import TimeGaugeView from './TimeGaugeView';

interface TimeGaugeProps {}

const TimeGauge: React.FC<TimeGaugeProps> = () => {
  const dispatch = useDispatch();
  const {
    remainingTime: nullableRemainingTime,
    duration,
    noGaugeTransition,
  } = useTimer();

  const remainingTime = nullableRemainingTime ?? 0;

  React.useEffect(() => {
    const timer = setInterval(() => {
      dispatch(timeActions.updateRemainingTime());
    }, 250);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [dispatch]);

  return (
    <>
      {/*<Prompt message='Are you sure you want to leave?' />*/}
      <TimeGaugeView
        remainingTime={remainingTime}
        duration={duration}
        noTransition={noGaugeTransition}
      />
    </>
  );
};

export default TimeGauge;
