import { Window } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import Colors, { colorWithAlpha } from '../../../../styles/colors';
import Button from '../../../uiElements/deprecated/Button';

interface ManualErrorOverlayProps {
  onClose: () => void;
  sendError: (message: string) => void;
}

const ManualErrorOverlay: React.FC<ManualErrorOverlayProps> = props => {
  const [message, setMessage] = React.useState('');
  const onMessageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) =>
      setMessage(event.target.value),
    [setMessage]
  );
  const onSend = React.useCallback(
    () => props.sendError(message),
    [message, props]
  );
  return (
    <ErrorOverlayWrapper>
      <TextBox>
        <Text1>{'お困りでしょうか？'}</Text1>
        <Text2>
          {
            'こちらでエラーの報告を受け付けています。お困りの内容を教えてください。'
          }
        </Text2>
        <InputArea
          value={message}
          onChange={onMessageChange}
          placeholder='エラー内容を入力してください'
        />
        <Buttons>
          <Button variant={'light'} onClick={props.onClose}>
            閉じる
          </Button>
          <Button onClick={onSend}>エラーを報告する</Button>
        </Buttons>
      </TextBox>
    </ErrorOverlayWrapper>
  );
};

const Text1 = styled.div`
  margin-top: 2rem;
  font-size: 9rem;
  font-weight: 700;
`;

const Text2 = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;

const InputArea = styled.textarea`
  width: 90rem;
  height: 24rem;
  font-size: 2rem;
  padding: 0.8rem 0.7rem;
  border: ${Colors.gray4} 1px solid;
  border-radius: 0.4rem;

  &::placeholder {
    color: ${Colors.gray4};
  }
`;

const TextBox = styled(Window)`
  text-align: center;
  position: absolute;
  width: 70%;
  height: 70%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const ErrorOverlayWrapper = styled.div`
  position: fixed;
  background-color: ${colorWithAlpha('white', 0.5)};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 11;
`;

const Buttons = styled.div`
  width: 60%;
  height: 6rem;
  position: absolute;
  top: 80%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  transform: translateX(-50%);
`;

export default ManualErrorOverlay;
