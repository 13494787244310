import { MainButton as _Button } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { instructionImgSrc } from '../../../lib/instructionImgSrc';
import CommonBG from '../../uiElements/CommonBG';

interface RuleInstrctionProps {
  stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const RuleInstrction: React.FC<RuleInstrctionProps> = props => {
  return (
    <CommonBG>
      {props.stageTitleImgSrc && <StageTitle src={props.stageTitleImgSrc} />}

      <Title src={instructionImgSrc[props.stageId].title} />

      <VideoWrapper>
        <video controls>
          <source
            src={instructionImgSrc[props.stageId].video_start}
            type='video/mp4'
          />
          <p>Your browser doesn't support HTML5 video.</p>
        </video>
      </VideoWrapper>

      <Rule src={instructionImgSrc[props.stageId].rule} />

      <Buttons>
        <Button color='negative' size='large' onClick={() => props.onBack?.()}>
          戻る
        </Button>
        <Button color='positive' size='large' onClick={() => props.onStart?.()}>
          スタート
        </Button>
      </Buttons>
    </CommonBG>
  );
};

const StageTitle = styled.img``;

const VideoWrapper = styled.div`
  width: 45%;
  margin: 4rem auto;
  border: solid 2px #6c7680;

  video {
    width: 100%;
    margin-bottom: -4px;
  }
`;

const Title = styled.img`
  display: block;
  height: 80px;
  width: auto;
  margin: 4rem auto 0;
`;

const Rule = styled.img`
  width: 45%;
  margin: 6rem auto;
  margin-top: 0;
  display: block;
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  max-width: 47%;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Buttons = styled.div`
  width: 35%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 10rem;
`;

export default RuleInstrction;
