import * as React from 'react';
import styled from 'styled-components';

import { env } from '../../lib/env';
import { colorWithAlpha } from '../../styles/colors';

interface DevEnvIndicatorProps {}
const DevEnvIndicator: React.FC<DevEnvIndicatorProps> = () => {
  const isDev = env === 'development';
  const isDevSt = env === 'devst';
  const isStaging = env === 'staging';

  if (!isDev && !isStaging && !isDevSt) return null;

  return (
    <DevEnvIndicatorWrapper>
      {isDevSt
        ? 'スキルトレーニング開発環境です！'
        : isDev
        ? '開発環境です！'
        : isStaging
        ? 'ステージング環境です！'
        : ''}
    </DevEnvIndicatorWrapper>
  );
};

const DevEnvIndicatorWrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  padding: 0.3rem 1rem;
  background-color: ${colorWithAlpha('primary', 0.4)};
`;

export default DevEnvIndicator;
