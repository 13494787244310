import { Footer } from '@riddler-co-jp/specc-ui-components';
import { FC } from 'react';
import styled from 'styled-components';

import BGImg from '../../static/png/bg.png';
import Colors, { colorWithAlpha } from '../../styles/colors';
import Header, { HeaderOptions } from './Header';

interface CommonBGProps {
  withHeader?: boolean;
  withFooter?: boolean;
  headerOptions?: HeaderOptions;
}
const CommonBG: FC<CommonBGProps> = props => {
  return (
    <CommonBGWrapper>
      {props.withHeader && <Header options={props.headerOptions} />}
      <ContentWrapper>{props.children}</ContentWrapper>
      {props.withFooter && <Footer />}
    </CommonBGWrapper>
  );
};

const ContentWrapper = styled.div`
  flex: 1 0 auto;
`;

const ContentMinWidth = '900px';

/**
 * border指定はこれ関連
 * http://shanabrian.com/web/html-css-js-technics/css-over-margin.php
 *
 */
interface CommonBGStyleProps {
  showBackgroundImage: boolean;
}

const CommonBGWrapper = styled.div`
  /* border: 1px solid transparent;
  box-sizing: border-box; */

  min-width: ${ContentMinWidth};

  background-image: url(${BGImg});
  background-repeat: repeat;
  background-size: 8rem 8rem;

  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  position: relative;
  color: ${Colors.gray8};

  /* https://css-tricks.com/couple-takes-sticky-footer/#article-header-id-3 */
  display: flex;
  flex-direction: column;
`;

export default CommonBG;
